import React from 'react'
import Fancybox from './FancyBox'
import img from "../../images/placeholder-mini.png"

const Gallery = ({gallery}) => {
  return (
    <div className='gallery'>
        <Fancybox
            options={{
                Carousel: {
                    infinite: false,
                },
            }}
        >
            <div className='gallery-grid'>
                {gallery?.map((card, index) =>
                    <div className="gallery-image" key={index}>
                        <a href={card} data-width="700" data-height="700"  target="_blank" data-fancybox="gallery">
                            <img src={card} id="img-1" data-zoom-image={card} className="img-fluid image_zoom_cls-0" alt=""
                                onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; 
                                        currentTarget.src = img
                                    }}
                            />
                        </a>
                    </div>
                )}
            </div>
        </Fancybox>
    </div>
  )
}

export default Gallery