import React, { useContext } from 'react'
import img from "../../images/about.png"
import { useParams } from 'react-router-dom'
import useFetchInfo from '../../hooks/useFetchInfo.hook'
import TournamentsServices from '../../services/general/tournamentsServices'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'
import TournamentDetailContainer from '../../components/complex/TournamentDetailContainer'

const TournamentDetail = observer(() => {
    const {slug} = useParams();
    const {app} = useContext(Context);

    const {info, loading} = useFetchInfo(TournamentsServices.view, slug, null, app.lang);

  return (
    <>
        {loading ?
            <div className='page-loader'>
                <span className="loader-circle"></span>
            </div>
            :
            <div className='article-detail'>
                <div className='article-detail-top' style={{background: info.detail_image ?
                    `url(${info.detail_image}) center center / cover no-repeat, linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000000b0 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #eee 100%)`
                    :
                    `url(${img}) center center / cover no-repeat, linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000000b0 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #eee 100%)`
                }}>
                    <div className="container">
                        <h1>{info.title}</h1>
                        <div className='article-detail-list'>
                            <div>
                                <i className='icon-calendar'></i>
                                {info.date}
                            </div>
                            <div className='line'/>
                            <div>
                                <i className='icon-eyes'></i>
                                {info.views}
                            </div>
                        </div>
                    </div>
                </div>
                <TournamentDetailContainer info={info}/>
            </div>
        }
    </>
  )
})

export default TournamentDetail