import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDetectOutsideClick } from '../../hooks/useDetectOutsideClick.hook'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'

const Menu = observer(() => {
    const {app} = useContext(Context);

    const [open, setOpen] = useState();
    const [sticky, setStiky] = useState();
    const [term, setTerm] = useState('');

    const [socials, setSocials] = useState();
    const [logo, setLogo] = useState();

    useEffect(() => {
        if(app.settings){
            setSocials(app.settings.find(it => it.type === "socials")?.value)
            setLogo(app.settings.find(it => it.type === "logo"))
        }
    }, [app.settings])

    const dropdownRef = useRef();
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

    const {
        items,
    } = useFetchItems(AppServices.menu, 'main-menu')

    const navigate = useNavigate();

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 120){
            setStiky(true)
        }else{
            setStiky(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

    const handleOpen = () => {
        setOpen(true);
        document.body.style.overflow = 'hidden'
    }

    const handleClose = () => {
        setOpen(false);
        document.body.style.overflow = 'auto'
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsActive(false)
        navigate(`/ru/search?term=${term}`)
    }

    const handleChange = (e) => {
        setTerm(e.target.value)
    }

  return (
    <div className={sticky ? 'menu sticky' : 'menu'}>
        <div className="container">
            <div className="menu-wrapper">
                <div className='burger-none' onClick={handleOpen}>
                    <i className='icon-burger'></i>
                </div>
                {logo && <NavLink to={'/'} className='logo-img'>
                    <img src={logo.value} alt="" />
                </NavLink>}
                <div className={isActive ? 'menu-wrapper-flex active' : 'menu-wrapper-flex'}>
                    <nav className={open ? 'menu-nav active' : 'menu-nav'}>
                        <div className='menu-top'>
                            <img src={logo} alt="" onClick={handleClose}/>
                            <span onClick={handleClose}>
                                <i className='icon-close'></i>
                            </span>
                        </div>
                        {items?.map((card, index) =>
                            card.items?.length >= 1 ?
                            <div className='dropdown-link' key={index}>
                                <NavLink to={card.value} onClick={handleClose} target={card.type}>{card.title}</NavLink>
                                    <div className='dropdown-list'>
                                        {card.items?.map((it, index) =>
                                            <NavLink key={index} target={it.type} to={it.value} onClick={handleClose}>{it.title}</NavLink>
                                        )}
                                    </div>
                            </div>
                            :
                            <NavLink to={card.value} target={card.type} onClick={handleClose} key={index}>{card.title}</NavLink>
                        )}

                        <div className='menu-bottom'>
                            {app.languages?.length > 1 &&
                                <div className='languages-menu'>
                                    {app.languages.map((lang, index) =>
                                        <div className={app.lang === lang.slug ? 'item-active' : ''} onClick={() => {app.setLang(lang.slug);}} key={index}>{lang.title}</div>
                                    )}
                                </div>
                            }
                            
                            <div className='socials'>
                                {socials?.map((social, index) =>
                                    <a href={social.value} key={index} target='_blank'>
                                        <i className={
                                            social.type === "whatsapp" ? 'icon-whatsapp1'
                                            :
                                            social.type === "facebook" ? 'icon-facebook'
                                            :
                                            social.type === "youtube" ? 'icon-youtube'
                                            :
                                            social.type === "telegram" ? 'icon-telegram'
                                            :
                                            social.type === "instagram" ? 'icon-mdi_instagram'
                                            :
                                            social.type === "twitter" ? 'icon-mdi_twitter'
                                            :
                                            social.type === "vk" ? 'ri_vk-fill'
                                            :
                                            ""
                                        }></i>
                                    </a>
                                )}
                            </div>
                        </div>
                    </nav>
                    <form className={isActive ? 'search active' : 'search'} ref={dropdownRef} onSubmit={handleSubmit}>
                        <i className='icon-search' onClick={() => setIsActive(true)}></i>
                        <input type="text" value={term} onChange={e => handleChange(e)} className='search-input' placeholder={app.localizationsItems.search}/>
                        {isActive && <i className='icon-close'  onClick={() => setIsActive(false)}></i>}
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
})

export default Menu