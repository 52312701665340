import React, { useContext } from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import RegionServices from '../../services/general/regionServices'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import Pagination from './Pagination'
import CompetitionCalendar from './CompetitionCalendar'
import CenterCard from '../simple/CenterCard'

const CentersContainer = observer(() => {
    const {app} = useContext(Context);

    const {
        items,
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(RegionServices.index)

  return (
    <>
        {loading ?
            <div className='page-loader'>
                <span className="loader-circle"></span>
            </div>
        :
        <div className='article-container category-mob-none'>
            <div className="container">
                <div className="article-container-grid">
                    <div className='centers-grid'>
                        {items?.items?.length >=1 ?
                            <>
                                {items.items.map((card, index) => 
                                    <CenterCard card={card} key={index}/>
                                )}
                            </>
                            :
                            <div className="text-none">{app.localizationsItems.no_data}</div>
                        }
                        <div className='custome-pagination'>
                            <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                        </div>
                    </div>
                    <div className='articles-bottom'>
                        <CompetitionCalendar/>
                    </div>
                </div>
            </div>
        </div>}
    </>
  )
})

export default CentersContainer