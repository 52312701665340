import CenterDetail from "../pages/centers/CenterDetail";
import CentersPage from "../pages/centers/CentersPage";
import TournamentDetail from "../pages/tournaments/TournamentDetail";
import { ARTICLES_PAGE_ROUTE, ARTICLE_DETAIL_PAGE_ROUTE, CENTERS_PAGE_ROUTE, CENTER_PAGE_ROUTE, DOCUMENTS_PAGE_ROUTE, ERROR_PAGE_ROUTE, FEDERATION_PAGE_ROUTE, MAIN_PAGE_ROUTE, MANAGMENT_PAGE_ROUTE, SAMPLE_PAGE_ROUTE, SEARCH_PAGE_ROUTE, TEAM_PAGE_ROUTE, TOURNAMENT_PAGE_ROUTE} from "./Constants";

import { lazy } from 'react';

const MainPage = lazy(() => import('../pages/main/MainPage'));
const ArticlesPage = lazy(() => import('../pages/articles/ArticlesPage'));
const ArticleDetail = lazy(() => import('../pages/articles/ArticleDetail'));
const SamplePage = lazy(() => import('../pages/sample/SamplePage'));
const DocumentsPage = lazy(() => import('../pages/sample/DocumentsPage'));
const AboutFederation = lazy(() => import('../pages/sample/AboutFederation'));
const SearchPage = lazy(() => import('../pages/sample/SearchPage'));
const ManagementPage = lazy(() => import('../pages/team/ManagementPage'));
const TeamPage = lazy(() => import('../pages/team/TeamPage'));
const ErrorPage = lazy(() => import('../pages/error/ErrorPage'));

export const publicRoutes = [
    {
        path: MAIN_PAGE_ROUTE,
        Component: MainPage
    },
    {
        path: '/',
        Component: MainPage
    },
    {
        path: ARTICLES_PAGE_ROUTE,
        Component: ArticlesPage
    },
    {
        path: ARTICLE_DETAIL_PAGE_ROUTE,
        Component: ArticleDetail
    },
    {
        path: SAMPLE_PAGE_ROUTE,
        Component: SamplePage
    },
    {
        path: DOCUMENTS_PAGE_ROUTE,
        Component: DocumentsPage
    },
    {
        path: TEAM_PAGE_ROUTE,
        Component: TeamPage
    },
    {
        path: FEDERATION_PAGE_ROUTE,
        Component: AboutFederation
    },
    {
        path: MANAGMENT_PAGE_ROUTE,
        Component: ManagementPage
    },
    {
        path: SEARCH_PAGE_ROUTE,
        Component: SearchPage
    },
    {
        path: ERROR_PAGE_ROUTE,
        Component: ErrorPage
    },
    {
        path: TOURNAMENT_PAGE_ROUTE,
        Component: TournamentDetail
    },
    {
        path: CENTER_PAGE_ROUTE,
        Component: CenterDetail
    },
    {
        path: CENTERS_PAGE_ROUTE,
        Component: CentersPage
    },
]
