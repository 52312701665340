import React, { useContext, useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { registerLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import "../../scss/ui/calendar.scss";
import useFetchItems from '../../hooks/useFetchItems';
import TournamentsServices from '../../services/general/tournamentsServices';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

registerLocale('ru', ru)

const CompetitionCalendar = observer(() => {
    const {app} = useContext(Context);
    
    const [date, setDate] = useState();
    const [tournaments, setTournaments] = useState();

    const [startDate, setStartDate] = useState(new Date());

    const [items, setItems] = useState();

    const [highlightedDates, setHighlightedDates] = useState();

    const fetchTournamets = async () => {
        const res = await TournamentsServices.dates();

        if(res.statusCode === 200){
            setItems(res.content)
        }
    }

    useEffect(() => {
        fetchTournamets();
    }, [])

    const handleChange = async (date) => {
        const tempdate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;

        const res = await TournamentsServices.datesView(tempdate);

        if(res.statusCode === 200){
            setTournaments(res.content.items)
        }

        setStartDate(date)
        
        if(highlightedDates.find(it => {
            if((it.getFullYear() === date.getFullYear()) && (it.getDate() === date.getDate()) && (it.getMonth() === date.getMonth())){
                return true
            }else{
                return false
            }
        })){
            const options = { month: 'long', day: 'numeric' };
            const formattedDate = date.toLocaleDateString('ru-RU', options);
            setDate(formattedDate)
        }else{
            setDate(null)
        }
    }

    useEffect(() => {
        if(items){
            let tempDates = [];

            items.items.forEach(element => {
                tempDates.push(new Date(element))
            });

            setHighlightedDates(tempDates)
        }
    }, [items])

    const handleMonthChange = (date) => {
        setDate(null)
    };

  return (
    highlightedDates &&
    <div className='calendar'>
        <h3>{app.localizationsItems.calendar_competions}</h3>
        <ReactDatePicker
            onChange={(date) => handleChange(date)}
            inline
            locale="ru"
            highlightDates={highlightedDates}
            onMonthChange={handleMonthChange}
        />
        {date && <div className='calendar-item'>
            <p>{date}</p>
            <ul>
                {tournaments?.map((card, index) => 
                    <li key={index}>
                        <NavLink to={`/${app.lang}/tournaments/${card.slug}`}>
                            {card.title}
                        </NavLink>
                    </li>
                )}
            </ul>
        </div>}
    </div>
  )
})

export default CompetitionCalendar