import http from "./http-common"
import {getData} from "../handlers";

export default class TournamentsServices {
    static index = (queryString) => {
        return getData(http, `/app/tournaments${queryString ? `?${queryString}` : ""}`);
    }

    static view = (slug, queryString) => {
        return getData(http, `/app/tournaments/${slug}${queryString ? `${queryString}` : ""}`);
    }

    static dates = (queryString) => {
        return getData(http, `/app/tournaments/dates${queryString ? `&${queryString}` : ""}`);
    }

    static datesView = (date) => {
        return getData(http, `/app/tournaments/dateView?date=${date}`);
    }
}