import i18next from "i18next";

export default class Utils {
    static numberWithSpaces(x) {
        if(x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    static getCookie(name) {
        var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    static isMarkerInsidePolygon(point, polygon) {
        const x = point[0];
        const y = point[1];

        let inside = false;

        for (let i = 0, j = polygon[0].length - 1; i < polygon[0].length; j = i++) {
            const xi = polygon[0][i].lat;
            const yi = polygon[0][i].lng;
            const xj = polygon[0][j].lat;
            const yj = polygon[0][j].lng;

            const intersect =
            yi > y !== yj > y &&
            x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

            if (intersect) {
                inside = !inside;
            }
        }

        return inside;
    };
    
    static setCookie(name, value, options = {}){
        options = {
            path: '/',
            ...options
          };
        
          if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
          }
        
          let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
        
          for (let optionKey in options) {
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];
            if (optionValue !== true) {
              updatedCookie += "=" + optionValue;
            }
          }
        
          document.cookie = updatedCookie;
    }

    static objectToSearchParams = (obj) => {
        return Object.entries(obj).map(([key, val]) => {
            if (val) {
                if (typeof val === 'object') {
                    return val.length ? val.map(val2 => `${key}[]=${val2}`).join('&') : null
                }
                else {
                    return `${key}=${val}`
                }
            } else {
                return null
            }
        }).filter(item => item)
            .join('&');
    }

    static trimText = (text, length, end = '') => {
        if(text.length >= 20){
            let index = text.indexOf(" ", length);

            if( index == -1 ) index = length;
            
            return text.slice(0, index) + end;
        }else{
            return text
        }
    }

    static generateOrderDate = (order_date) => {
        const date = new Date(order_date);

        var d = date.getDate();
        var m = date.getMonth() + 1;
        var y = date.getFullYear();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        return '' + (d <= 9 ? '0' + d : d) + '.' + (m<=9 ? '0' + m : m) + '.' + y + ',' + hours + ':' + minutes;
    }
}
