import React, { useContext, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Context } from '../..'
import useFetchInfo from '../../hooks/useFetchInfo.hook'
import RegionServices from '../../services/general/regionServices'
import SamplePageContainer from '../../components/complex/SamplePageContainer'
import img from "../../images/placeholder.png"

const CenterDetail = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();
    const {slug} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}/centers/${slug}`);
            }
        }
    }, [app.lang])

    const {info, loading} = useFetchInfo(RegionServices.view, slug, null, app.lang);

  return (
    <div>
        <div className='page-top' style={{backgroundImage: info?.detail_image ? `url(${info.detail_image})` : `url(${img})`}}>
            <div className="container">
                <h1 className='mini'>{info?.title}</h1>
                <ul>
                    <li>
                        <NavLink to={`/${app.lang}`}>{app.localizationsItems.main}</NavLink>
                    </li>
                    <li>
                        <i className='icon-arrow-right'></i> 
                    </li>
                    <li>
                        <NavLink to={`/${app.lang}/centers`}>{app.localizationsItems.centers}</NavLink>
                    </li>
                    <li>
                        <i className='icon-arrow-right'></i> 
                    </li>
                    <li>{info?.title}</li>
                </ul>
            </div>
        </div>
        {loading ?
            <div className='page-loader'>
                <span className="loader-circle"></span>
            </div>
            :
            <SamplePageContainer item={info}/>
        }
    </div>
  )
})

export default CenterDetail