import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import img from "../../images/placeholder.png"
import { observer } from 'mobx-react-lite'
import { Context } from '../..'

const PageTop = observer(({item}) => {
    const {app} = useContext(Context);

  return (
    <div className='page-top' style={{backgroundImage: item?.detail_image ? `url(${item.detail_image})` : `url(${img})`}}>
        <div className="container">
            <h1 className='mini'>{item?.title}</h1>
            <ul>
                <li>
                    <NavLink to={'/'}>{app.localizationsItems.main}</NavLink>
                </li>
                <li>
                   <i className='icon-arrow-right'></i> 
                </li>
                <li>{item?.title}</li>
            </ul>
        </div>
    </div>
  )
})

export default PageTop