export const MAIN_PAGE_ROUTE = '/:lang'

export const ARTICLES_PAGE_ROUTE = '/:lang/articles'
export const ARTICLE_DETAIL_PAGE_ROUTE = '/:lang/articles/:slug'
export const SAMPLE_PAGE_ROUTE = '/:lang/page/:slug'
export const DOCUMENTS_PAGE_ROUTE = '/:lang/documents'
export const TEAM_PAGE_ROUTE = '/:lang/professionals'
export const FEDERATION_PAGE_ROUTE = '/:lang/federation'
export const MANAGMENT_PAGE_ROUTE = '/:lang/managment'
export const SEARCH_PAGE_ROUTE = '/:lang/search'
export const ERROR_PAGE_ROUTE = '/:lang/error'
export const TOURNAMENT_PAGE_ROUTE = '/:lang/tournaments/:slug'
export const CENTER_PAGE_ROUTE = '/:lang/centers/:slug'
export const CENTERS_PAGE_ROUTE = '/:lang/centers'
