import React, { useContext } from 'react'
import img from "../../images/placeholder-mini.png"
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'

const CenterCard = observer(({card}) => {
    const {app} = useContext(Context);

  return (
    <NavLink to={`/${app.lang}/centers/${card.slug}`} className='article-card'>
        <div className='position-relative'>
            <img src={card.image ? card.image : img} alt="" 
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
            />
            <div className='linear-gradient'></div>
        </div>
        <div className='article-card-wrapper'>
            <div className='date'>
                <i className='icon-calendar'></i>
                <span>{card.date}</span>
            </div>
            <h5>{card.title}</h5>
            <p>{card.description}</p>
        </div>
    </NavLink>
  )
})

export default CenterCard