import {makeAutoObservable} from "mobx";

export default class AppStore {
    constructor() {
        this._settings = []
        this._localizationsItems = {
            lang: 'Язык',
            all_news: "Все новости",
            prev_new: "Предыдущая новость",
            next_new: "Следующая новость",
            tags: "Теги",
            no_data: "Страница в разработке...",
            calendar_competions: "Календарь соревнований",
            subscribe_news: "Подписаться на новости",
            subscribe: "Подписаться",
            footer_bottom: "Национальная Федерация Падел Республики Казахстан",
            send: "Отправить",
            latest_news: "Последние новости",
            find: "Найти",
            email: "Почта",
            news_categories: "Категории",
            all: "Все",
            share: "Поделиться",
            main: "Главная",
            search: "Введите слова для поиска...",
            no_padel_centers: "В данном регионе отсутствуют падел-центры",
            centers: "Падель центры"
        }
        this._lang = localStorage.getItem('i18nextLng')
        this._languages = []
        this._userCabinet = true
        this._bonuses = false
        this._minimumOrderAmount = null
        this._registerByPhone = false
        makeAutoObservable(this)
    }

    setRegisterByPhone(registerByPhone) {
        this._registerByPhone = registerByPhone
    }

    setSettings(settings) {
        this._settings = settings
    }

    setLocalizationsItems(localizationsItems) {
        this._localizationsItems = localizationsItems
    }

    setLang(lang) {
        localStorage.setItem('i18nextLng', lang)
        this._lang = lang
    }

    setLanguages(languages) {
        this._languages = languages
    }

    setUserCabinet(userCabinet) {
        this._userCabinet = userCabinet
    }

    setBonuses(bonuses) {
        this._bonuses = bonuses
    }

    setMinimumOrderAmount(minimumOrderAmount) {
        this._minimumOrderAmount = minimumOrderAmount
    }

    get registerByPhone() {
        return this._registerByPhone
    }

    get settings() {
        return this._settings
    }

    get localizationsItems() {
        return this._localizationsItems
    }

    get lang() {
        return this._lang
    }

    get languages() {
        return this._languages
    }

    get userCabinet() {
        return this._userCabinet
    }

    get minimumOrderAmount() {
        return this._minimumOrderAmount
    }
    
    get bonuses() {
        return this._bonuses
    }

}