import React, { useContext, useEffect, useState } from 'react'
import LatestArticleCard from '../simple/LatestArticleCard'
import ArticleServices from '../../services/general/articleServices'
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const LatestArticles = observer(({gray}) => {
    const {app} = useContext(Context);
    
    const [items, setItems] = useState();

    const fetchArticles = async () => {
        const res = await ArticleServices.index();

        if(res.statusCode === 200){
            setItems(res.content)
        }
    }
    
    useEffect(() => {
        fetchArticles();
    }, [])

  return (
    <div className={gray ? 'latest-articles gray' : 'latest-articles'}>
        <h3>{app.localizationsItems.latest_news}</h3>
        <div>
            {items?.items?.slice(0, 4).map((card, index) =>
                <LatestArticleCard card={card} key={index}/>
            )}
        </div>
    </div>
  )
})

export default LatestArticles