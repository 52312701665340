import React, { useContext, useEffect } from 'react'
import PageTop from '../../components/simple/PageTop'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '../..'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'
import { observer } from 'mobx-react-lite'
import CentersContainer from '../../components/complex/CentersContainer'

const CentersPage = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}/centers`);
            }
        }
    }, [app.lang])

    const {
        items,
        loading,
        setFilterData
    } = useFetchItems(AppServices.page, 'centers')

  return (
    <div>
        <PageTop item={items}/>
        <CentersContainer/>
    </div>
  )
})

export default CentersPage