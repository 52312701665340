import React from 'react'
import img from "../../images/error.png"
import "../../scss/complex/error.scss"
import { NavLink } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <div className='container'>
        <div className="error">
            <NavLink to='/'>Вернуться на главную</NavLink>
            <img src={img} alt="" width={200} height={260}/>
            <h1>404</h1>
            <h3>Страница не найдена</h3>
            <p>Не удалось найти страницу</p>
        </div>
    </div>
  )
}

export default ErrorPage