import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/appServices'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'

const Footer = observer(() => {
    const {app} = useContext(Context);

    const [logo, setLogo] = useState();
    const [socials, setSocials] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [text, setText] = useState();

    useEffect(() => {
        if(app.settings){
            setLogo(app.settings.find(it => it.type === "white-logo"));
            setSocials(app.settings.find(it => it.type === "socials")?.value);
            setEmail(app.settings.find(it => it.type === "email")?.value);
            setPhone(app.settings.find(it => it.type === "phone")?.value);
            setText(app.settings.find(it => it.type === "footer_text")?.value);
        }
    }, [app.settings])

    const {
        items,
    } = useFetchItems(AppServices.menu, 'bottom-menu')

  return (
    <footer className='footer'>
        <div className="container">
            <div className='footer-wrapper'>
                {logo && <NavLink to={'/'} className={'footer-logo'}>
                    <img src={logo.value} alt="" />
                </NavLink>}
                {items?.map((card, index) =>
                    <nav className='footer-nav' key={index}>
                        {card.items?.map((it, index) =>
                            <NavLink to={it.value} key={index}>{it.title}</NavLink>
                        )}
                    </nav>
                )}
                <div>
                    {text?.map((em, index) => 
                        <p key={index} className="footer-text">{em}</p>
                    )}
                    <div className='footer-data'>
                        {phone?.map((el, index) =>
							<a href={`tel:${el}`} key={index}>{el}</a>
                        )}
                    </div>
                    <div className='footer-data'>
                        {email?.map((el, index) =>
							<a href={`mailto:${el}`} key={index}>{el}</a>
                        )}
                    </div>
                    <div className='footer-socials'>
                        {socials?.map((social, index) =>
                                <a href={social.value} key={index} target='_blank'>
                                    <i className={
                                        social.type === "whatsapp" ? 'icon-whatsapp1'
                                        :
                                        social.type === "facebook" ? 'icon-facebook'
                                        :
                                        social.type === "youtube" ? 'icon-youtube'
                                        :
                                        social.type === "telegram" ? 'icon-telegram'
                                        :
                                        social.type === "instagram" ? 'icon-mdi_instagram'
                                        :
                                        social.type === "twitter" ? 'icon-mdi_twitter'
                                        :
                                        social.type === "vk" ? 'ri_vk-fill'
                                        :
                                        ""
                                    }></i>
                                </a>
                        )}
                    </div>
                </div>
            </div>
            <div className='footer-bottom'>{app.localizationsItems.footer_bottom}</div>
        </div>
    </footer>
  )
})

export default Footer