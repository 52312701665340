import React, { Suspense, useCallback, useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Context } from '.'
import AppServices from './services/general/appServices'
import AppRouter from './navigation/AppRouter'
import { BrowserRouter } from 'react-router-dom';
import Header from './components/complex/Header'
import './i18n/i18n'
import './App.scss';
import Footer from './components/complex/Footer'
import ScrollToTop from './hooks/scrollToTop'

const App = observer(() => {
    const {app} = useContext(Context);
    const [loading, setLoading] = useState();

    const fetchdata = async () => {
        setLoading(true);
        const res = await AppServices.settings();

        if(res.statusCode === 200){
            const settings = res.content;
            app.setSettings(settings);

            let color = settings.find(it => it.type === "color-primary")?.value;

            color && document.documentElement.style.setProperty('--mainColor', color);
        }

        const res2 = await AppServices.localizations();

        if(res2.statusCode === 200){
            const langSlug = localStorage.getItem('i18nextLng') || res2.content[0]?.slug;
            localStorage.setItem('i18nextLng', langSlug);
            app.setLang(langSlug);

            let localizations = res2.content.find(it => it.slug === langSlug)?.description;

            let map2 = app.localizationsItems;
            for (let key in localizations) {
                map2[key] = localizations[key];
            }
            
            app.setLocalizationsItems(map2)

            app.setLanguages(res2.content)
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchdata();
    }, [app.lang])

  return (
    <>
        {loading ?
            <div className='loader-container'>
                <span className="loader"></span>
            </div>
            :
            <BrowserRouter>
                <ScrollToTop/>
                <Header/>
                <Suspense fallback={<div className='page-loader'><span className='page-loader-span'/></div>}>
                    <AppRouter/>
                </Suspense>
                <Footer/>
            </BrowserRouter>
        }
    </>
  )
})

export default App