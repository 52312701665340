import http from "./http-common"
import {getData} from "../handlers";

export default class RegionServices {
    static index = (queryString) => {
        return getData(http, `/app/padelCenters${queryString ? `?${queryString}` : ""}`);
    }

    static view = (slug, queryString) => {
        return getData(http, `/app/padelCenters/${slug}${queryString ? `${queryString}` : ""}`);
    }

    static regions = (slug, queryString) => {
        return getData(http, `/app/dictionaries/regions`);
    }
}