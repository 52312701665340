import React, { useContext } from 'react'
import img from "../../images/plaholder.png"
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'

const LatestArticleCard = observer(({card}) => {
    const {app} = useContext(Context);
    
  return (
    <NavLink to={`/${app.lang}/articles/${card.slug}`} className='latest-article-card'>
        <img src={card.image ? card.image : img} alt="" 
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; 
                currentTarget.src = img
            }}
        />
        <div className='latest-article-content'>
            <p>{card.title}</p>
            <div className='date'>
                <i className='icon-calendar'></i>
                <span>{card.date}</span>
            </div>
        </div>
    </NavLink>
  )
})

export default LatestArticleCard