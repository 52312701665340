import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import {
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TelegramIcon,
    TwitterIcon,
    FacebookIcon,
    WhatsappIcon
} from "react-share";
import { Context } from '../..';

const ShareBlock = observer(() => {
    const shareUrl = window.location.href;

    const {app} = useContext(Context);

  return (
    <div className='share-block'>
        <div>{app.localizationsItems.share}</div>
        <div className='share-block-wrapper'>
            <FacebookShareButton url={shareUrl}><FacebookIcon size={30} round={true}></FacebookIcon></FacebookShareButton>
            <TwitterShareButton url={shareUrl}><TwitterIcon size={30} round={true}></TwitterIcon></TwitterShareButton>
            <WhatsappShareButton url={shareUrl}><WhatsappIcon size={30} round={true}></WhatsappIcon></WhatsappShareButton>
            <TelegramShareButton url={shareUrl}><TelegramIcon size={30} round={true}></TelegramIcon></TelegramShareButton>
        </div>
    </div>
  )
})

export default ShareBlock