import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../..';

const TopBar = observer(() => {
    const {app} = useContext(Context);

    const [socials, setSocials] = useState();

    useEffect(() => {
        if(app.settings){
            setSocials(app.settings.find(it => it.type === "socials")?.value)
        }
    }, [app.settings])

  return (
    <div className='topbar'>
        <div className="container">
            <div className="topbar-wrapper">
                <div className='topbar-socials'>
                    {socials?.map((social, index) =>
                        <a href={social.value} key={index} target='_blank'>
                            <i className={
                                social.type === "whatsapp" ? 'icon-whatsapp1'
                                :
                                social.type === "facebook" ? 'icon-facebook'
                                :
                                social.type === "youtube" ? 'icon-youtube'
                                :
                                social.type === "telegram" ? 'icon-telegram'
                                :
                                social.type === "instagram" ? 'icon-mdi_instagram'
                                :
                                social.type === "twitter" ? 'icon-mdi_twitter'
                                :
                                social.type === "vk" ? 'ri_vk-fill'
                                :
                                ""
                            }></i>
                        </a>
                    )}
                </div>
                {app.languages?.length > 1 &&
                    <div className='languages'>
                        {app.languages.map((lang, index) =>
                            <span className={app.lang === lang.slug ? 'active' : ''} onClick={() => app.setLang(lang.slug)} key={index}>{lang.title}</span>
                        )}
                    </div>
                }
            </div>
        </div>
    </div>
  )
})

export default TopBar