import React from 'react'
import FormContact from './FormContact'

const FormComponent = ({elem}) => {
  return (
    <div className="container">
        <div>
            <div className='description'>
                <h4>{elem.subtitle}</h4>
                <div dangerouslySetInnerHTML={{__html: elem.description}}></div>
                <div className='d-flex'>
                    {elem.items?.map((card, index) =>
                        <div key={index} dangerouslySetInnerHTML={{__html: card.description}}></div>
                    )}
                </div>
            </div>
            <div>
                <FormContact elem={elem}/>
            </div>
        </div>
    </div>
  )
}

export default FormComponent