import React from 'react'
import TopBar from '../simple/TopBar'
import Menu from '../simple/Menu'

const Header = () => {
  return (
    <header>
        <TopBar/>
        <Menu/>
    </header>
  )
}

export default Header