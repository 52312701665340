import React from 'react'
import { NavLink } from 'react-router-dom'
import LatestArticles from './LatestArticles'
import CompetitionCalendar from './CompetitionCalendar'
import FormComponent from './FormComponent'

const SamplePageContainer = ({item}) => {
  return (
    <div className="container">
        <div className='sample-detail-grid'>
            <div>
            <div className='description' dangerouslySetInnerHTML={{__html: item?.description}}></div>
            {item?.elements?.map((elem, index) =>
                <div key={index}>
                    {elem.type === "form" ?
                        <FormComponent elem={elem}/>
                        :
                        <></>
                    }
                </div>
            )}
            </div>
            <div>
                <CompetitionCalendar/>
                <LatestArticles gray={true}/>
            </div>
        </div>
    </div>
  )
}

export default SamplePageContainer