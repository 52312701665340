import React from 'react'
import Gallery from './Gallery'
import ShareBlock from '../simple/ShareBlock'
import LatestArticles from './LatestArticles'
import CompetitionCalendar from './CompetitionCalendar'

const TournamentDetailContainer = ({info}) => {

  return (
    <div className="container category-mob-none">
        <div className='article-detail-grid'>
            <div>
                <div className='description' dangerouslySetInnerHTML={{__html: info.description}}></div>
                {info.gallery && <Gallery gallery={info.gallery}/>}
                <ShareBlock/>
            </div>
            <div>
                <CompetitionCalendar/>
                <LatestArticles/>
            </div>
        </div>
    </div>
  )
}

export default TournamentDetailContainer