import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { Context } from '../..'
import Utils from '../../services/utils';
import AppServices from '../../services/general/appServices';
import { useForm } from 'react-hook-form';

const FormContact = observer(({elem}) => {
    const {app} = useContext(Context);
    const [loading, setLoading] = useState();

    const {handleSubmit, register, reset, control, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        setLoading(true);
    
        const newData = {
            fields: {}
        };
    
        for (const key in data) {
            const temp = elem.elements.find(it => it.name === key);
            if (temp) {
                newData.fields[temp.label] = data[key];
            }
        }
    
        const query = new URLSearchParams(window.location.search);
    
        if (query.toString()) {
            const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
            utmParams.forEach(param => {
                const value = query.get(param);
                if (value) {
                    newData[param] = value;
                }
            });
        } else {
            const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
            utmParams.forEach(param => {
                const cookieValue = Utils.getCookie(param);
                if (cookieValue) {
                    newData[param] = cookieValue;
                }
            });
        }
    
        const res = await AppServices.request(newData);

        if (res.statusCode === 200) {
            setTimeout(() => {
                reset();
            }, 3000);
            //toast.success(res.message);
        } else {
            //toast.error(res.message);
        }

        setLoading(false);
    };

  return (
    <form className='form-contact' onSubmit={handleSubmit(CustomSubmit)}>
        <h3>{elem.title}</h3>
        {elem.elements?.map((card, index) =>
            card.name === "textarea" ?
                <textarea {...register(card.name)} key={index} type="text" className='textarea-df' placeholder={card.placeholder} name={card.name}/>
            :
                <input {...register(card.name)} key={index} type="text" className='input-df' placeholder={card.placeholder} name={card.name}/>
        )}
        <button className='main-button'>{app.localizationsItems.send}</button>
    </form>
  )
})

export default FormContact